@import url("https://fonts.googleapis.com/css?family=Jersey+20:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Instrument+Serif:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Junge:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
