.whole {
    min-height: 100vh;
    box-sizing: border-box;
    background-color: black;
    padding: 144px;
}
.explain {
    font-size: 36px !important;
    color: #6e6d6d;
    letter-spacing: -0.04em;
}
.total {
    display: flex;
    padding-top: 74px;
}
.left {
    width: 50%;
}
.title {
    font-size: 48px;
    font-weight: 700;
    color: white;
    line-height: 32px;
    letter-spacing: -0.04em;
}
.content {
    font-size: 20px;
    color: white;
    line-height: 32px;
    letter-spacing: -0.04em;
    padding-bottom: 40px;
}

.right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button {
    background-color: black;
    color: white;
    font-size: 48px;
    font-weight: bold;
    line-height: 69px;
    letter-spacing: -0.04em;
    padding: 12px 24px;
    border: 1px solid white;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .whole {
        padding: 50px;
    }
    .total {
        padding-top: 36px;
        flex-direction: column;
    }
    .explain {
        font-size: 20px;
        display: flex;
        justify-content: center;
    }
    .title {
        font-size: 30px;
    }
    .content {
        font-size: 16px;
        padding-bottom: 20px;
    }
    .left {
        width: 100%;
    }
    .right {
        width: 100%;
    }
}

.loading_container {
    background-color: black;
    padding-bottom: 0px;
}
